import { AuthenticationResult } from '@azure/msal-browser';
import { createReducer, on } from '@ngrx/store';
import { AuthMode } from 'src/core/enums/permissions.enum';
import { LocalStorage } from 'src/core/enums/store-constants.enum';
import { OrganizationData } from 'src/core/interfaces/organization.interface';
import { UserData } from 'src/core/interfaces/user/user.interface';
import { AuthActions, AuthModeActions, authActionLogOut } from './auth.actions';

export interface AuthState {
  user: UserData;
  token: string;
  organization: OrganizationData;
  msAuthentication: AuthenticationResult;
}

const initialState: AuthState = {
  token: '',
  user: {
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: null,
    grade: null,
    reportsTo: null,
    subordinates: [],
  },
  organization: {
    name: '',
    website: '',
    logo: '',
    _id: '',
  },
  msAuthentication: null,
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.saveToken, (state, { token }) => {
    localStorage.setItem(LocalStorage.jwtToken, token);

    return { ...state, token };
  }),

  on(AuthActions.saveUserProfile, (state, { userProfileData }) => ({
    ...state,
    user: userProfileData,
  })),

  on(AuthActions.saveMSProfile, (state, { msProfileData }) => ({
    ...state,
    msAuthentication: msProfileData,
  })),

  on(authActionLogOut, () => initialState),
);

export const authModeReducer = createReducer(
  AuthMode.Regular,

  on(AuthModeActions.setMode, (_, { mode }) => mode),

  on(authActionLogOut, () => AuthMode.Regular),
);
