import { IconType } from './icon.enum';

export enum PreviewStepMode {
  Survey = 'assessments',
  Details = 'details',
}

export enum PRCycleStepType {
  SelfAssessment = 'self-assessment',
  ManagerAssessment = 'manager-assessment',
  PerformanceDiscussions = 'performance-discussions',
}

export enum SurveyOn {
  Questions = 'questions',
  Skills = 'skills',
}

export const StepTitles = {
  [PRCycleStepType.SelfAssessment]: 'Self assessment',
  [PRCycleStepType.ManagerAssessment]: 'Manager assessment',
  [PRCycleStepType.PerformanceDiscussions]: 'Performance discussions',
};

export const StepIcons = {
  [PRCycleStepType.SelfAssessment]: IconType['user-speak'],
  [PRCycleStepType.ManagerAssessment]: IconType['user-check'],
  [PRCycleStepType.PerformanceDiscussions]: IconType['call-chat'],
};

export const ActionIcon = {
  [PRCycleStepType.SelfAssessment]: {
    name: IconType['info-square'],
    label: 'Info',
  },
  [PRCycleStepType.ManagerAssessment]: {
    name: IconType['info-square'],
    label: 'Info',
  },
  [PRCycleStepType.PerformanceDiscussions]: {
    name: IconType['calendar-plus'],
    label: 'Book',
  },
};
