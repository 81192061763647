import { createActionGroup, props } from '@ngrx/store';
import {
  NGRXPRStateSource,
  NGRXPRLayoutStateType,
  NGRXPRCycleStateType,
} from './performance-review-action.types';
import {
  PRCycleStepType,
  PreviewStepMode,
  SurveyOn,
} from 'src/core/enums/pr-layout.enum';
import { ReviewCycle } from 'src/core/interfaces/review-cycle.interface';

export const PRLayoutActions = createActionGroup({
  source: NGRXPRStateSource.PRLayoutStateSource,
  events: {
    [NGRXPRLayoutStateType.setStepType]: props<{ step: PRCycleStepType }>(),
    [NGRXPRLayoutStateType.setViewMode]: props<{ mode: PreviewStepMode }>(),
    [NGRXPRLayoutStateType.setSurveyOn]: props<{ surveyOn: SurveyOn }>(),
  },
});

export const PRCycleActions = createActionGroup({
  source: NGRXPRStateSource.PRCycleStateSource,
  events: {
    [NGRXPRCycleStateType.setCycle]: props<{ cycle: ReviewCycle }>(),
    [NGRXPRCycleStateType.setUser]: props<{ userId: string }>(),
  },
});
