import { createReducer, on } from '@ngrx/store';
import { nanoid } from 'nanoid';
import { FeedbackTemplateData } from 'src/core/interfaces/feedback.interface';
import { AuthService } from 'src/core/services/auth.service';
import {
  FeedbackTemplateActions,
  removeAllFeedbackTemplates,
} from './feedback-template.actions';
import {
  FeedbackCategory,
  FeedbackQuestionType,
} from 'src/core/enums/feedback.enum';

export interface FeedbackTemplateState {
  templates: FeedbackTemplateData[];
}

const initialState: FeedbackTemplateState = {
  templates: [],
};

export const feedbackTemplateReducer = createReducer(
  initialState,

  on(FeedbackTemplateActions.loadTemplatesFromDB, (state, { templates }) => {
    const storeTemplates = [
      {
        _id: 'template0',
        userId: null,
        title: 'Basic review',
        description: '',
        sections: [],
        category: FeedbackCategory.General,
      },
      ...templates,
    ].map((template) => ({
      ...template,
      selected: false,
    }));

    return {
      ...state,
      templates: storeTemplates,
    };
  }),

  on(FeedbackTemplateActions.addTemplate, (state, { templateId }) => ({
    ...state,
    templates: [
      ...state.templates.map((template) => ({
        ...template,
        selected: false,
      })),
      {
        _id: templateId,
        title: 'New template',
        description: '',
        userId: AuthService.getUser()?._id,
        sections: [
          {
            skill: null,
            questions: [
              {
                _id: nanoid(7),
                title: 'This one is mandatory',
                type: FeedbackQuestionType.text,
                required: true,
              },
            ],
          },
        ],
        selected: true,
        category: FeedbackCategory.General,
      },
    ],
  })),

  on(FeedbackTemplateActions.updateTemplate, (state, { template }) => ({
    ...state,
    templates: state.templates.map((item) =>
      template._id === item._id ? template : item,
    ),
  })),

  on(FeedbackTemplateActions.updateTemplateTitle, (state, { title }) => {
    const selectedTemplate = state.templates.find(
      (template) => template.selected,
    );

    return {
      ...state,
      templates: state.templates.map((template) =>
        template._id === selectedTemplate._id
          ? { ...selectedTemplate, title }
          : template,
      ),
    };
  }),

  on(FeedbackTemplateActions.selectTemplate, (state, { templateId }) => ({
    ...state,
    templates: state.templates.map((template) => ({
      ...template,
      selected: template._id === templateId,
    })),
  })),

  on(FeedbackTemplateActions.deleteTemplate, (state, { templateId }) => ({
    ...state,
    templates: state.templates
      .filter((template) => template._id !== templateId)
      .map((template, index) => ({
        ...template,
        selected: index === 0,
      })),
  })),

  on(removeAllFeedbackTemplates, () => initialState),
);
