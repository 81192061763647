import { createReducer, on } from '@ngrx/store';
import {
  PRCycleStepType,
  PreviewStepMode,
  SurveyOn,
} from 'src/core/enums/pr-layout.enum';
import { PRCycleActions, PRLayoutActions } from './performance-review.actions';
import { ReviewCycle } from 'src/core/interfaces/review-cycle.interface';

export interface PRLayoutState {
  step: PRCycleStepType;
  mode: PreviewStepMode;
  surveyOn: SurveyOn;
}

export interface PRCycleState {
  userId: string;
  cycle: ReviewCycle;
}

const initialLayoutState: PRLayoutState = {
  step: PRCycleStepType.SelfAssessment,
  mode: PreviewStepMode.Details,
  surveyOn: SurveyOn.Questions,
};

export const prLayoutReducer = createReducer(
  initialLayoutState,

  on(PRLayoutActions.setStepType, (state, { type }) => ({
    ...state,
    type,
  })),

  on(PRLayoutActions.setViewMode, (state, { mode }) => ({
    ...state,
    mode,
  })),

  on(PRLayoutActions.setSurveyOn, (state, { surveyOn }) => ({
    ...state,
    surveyOn,
  })),
);

const initialCycleState: PRCycleState = {
  userId: '',
  cycle: null,
};

export const prCycleReducer = createReducer(
  initialCycleState,

  on(PRCycleActions.setUser, (state, { userId }) => ({
    ...state,
    userId,
  })),

  on(PRCycleActions.setCycle, (state, { cycle }) => ({
    ...state,
    cycle,
  })),
);
