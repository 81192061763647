
export enum NGRXPRLayoutStateType {
  setStepType = 'Set Step Type',
  setViewMode = 'Set View Mode',
  setSurveyOn = 'Set Survey On',
}

export enum NGRXPRCycleStateType {
  setCycle = 'Set Cycle',
  setUser = 'Set User',
}

export enum NGRXPRStateSource {
  PRLayoutStateSource = 'PR Layout State Source',
  PRCycleStateSource = 'PR Cycle State Source',
}
